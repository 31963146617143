.open-vault__main {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
}
.open-vault__container-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0;
}
.open-vault__input {
    margin-right: 20px;
}
.open-vault__button {
    width: 244px;
}
.open-vault__container {
    display: grid;
    width: 100%;
    box-sizing: border-box;
    grid-template-columns: 74% 26%;
}

.open-vault__container-left {
    box-sizing: border-box;
    margin: 20px 0 20px 20px;
}
.open-vault__container-right {
    margin: 20px;
}
.open-vault__table {
    border-radius: 7px;
    margin-top: 20px;
}
.open-vault__table .table__title {
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 20px 20px;
    padding-top: 20px;
}
.open-vault__cards {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin-bottom: 20px;
}
.open-vault__card-notification {
    height: 490px;
    overflow-y: auto;
}
.open-vault__modal {
    width: 37%;
}
.open-vault__modal__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.open-vault__modal__text {
    width: 90%;
    text-align: center;
}
.open-vault__modal__icon {
    background: rgba(40, 114, 72, 0.1);
    width: 130px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 20px;
}
