.shipments_out__columns {
    display: flex;
    justify-content: space-between;
    padding: 30px 45px;
}
.shipments_out__columns--right {
    width: 50%;
    padding: 30px;
    height: fit-content;
}
.shipments_out__columns--left {
    width: 45%;
}
.shipments_out__columns__title {
    font-weight: 700;
    font-size: 20px;
}
.shipments_out__columns__form__section__inputs {
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-top: 20px;
}

.shipments_out__columns__form__section__inputs .select .select__label--placeholder {
    width: 70%;
}

.shipments_out__columns__form__section__input--1 {
    grid-area: 1 / 1 / 2 / 2;
}
.shipments_out__columns__form__section__input--2 {
    grid-area: 1 / 2 / 2 / 3;
}
.shipments_out__columns__form__section__input--3 {
    grid-area: 2 / 1 / 3 / 2;
}
.shipments_out__columns__form__section__input--4 {
    grid-area: 2 / 2 / 3 / 3;
}
.shipments_out__columns__form {
    margin-top: 30px;
}
.shipments_out__columns__form__section__title {
    font-weight: 700;
    font-size: 16px;
}
.shipments_out__columns__form__section__inputs__title {
    font-size: 12px;
}
.shipments_out__columns__form__section__inputs__item {
    width: fit-content;
}
.shipments_out__columns__form__section {
    display: block;
    margin-bottom: 30px;
}
.shipments_out__columns__form__section--additional {
    display: none;
}
.shipments_out__columns__values--title {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 15px;
}
.shipments_out__columns__card {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 30px;
}
.shipments_out__columns__card__title {
    font-weight: 700;
    font-size: 14px;
}
.shipments_out__columns__values--amount,
.shipments_out__columns__values--amount-add {
    font-size: 12px;
}
.shipments_out__columns__values {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.shipments_out__columns__values--amount-add {
    width: 17%;
    display: flex;
    justify-content: flex-end;
}
.shipments_out__columns__link {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
}
.shipments_out__table {
    margin: 20px 30px;
    width: 95%;
}
