.header {
    background-color: #fff;
}
.header__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 15px 35px;
}
.header__logo {
    font-weight: 700;
    font-size: 18px;
    width: 10%;
    text-align: left;
    line-height: 17px;
    padding: 0px 20px;
}
.header__option--selected,
.header__option {
    padding: 5px 20px;
    margin: 0px 10px;
}
.header__option--selected {
    background: rgba(72, 176, 80, 0.1);
    border-radius: 5px;
    font-weight: 700;
    font-size: 16px;
    color: #287248;
}
.header__dropdowns__item {
    font-weight: 600;
    font-size: 16px;
    color: #287248;
    padding: 10px 20px;
    border: 1px solid #287248;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 120%;
    margin: 0px 10px;
}
.header__dropdowns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 70%;
}
.header__icons__user {
    display: flex;
}
.header__icons {
    display: flex;
    margin-left: 60px;
    justify-content: space-between;
    width: 7%;
}
