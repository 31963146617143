.denomination-switch__main {
    width: 100%;
    background: white;
}
.denomination-switch__text-money {
    font-size: 48px;
    font-weight: 400;
    line-height: 82px;
    color: var(--green);
    text-align: center;
}
.denomination-switch__container-toggle {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
}
.denomination-switch__collapse {
    width: 100%;
    height: auto;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: none;
    border-radius: none;
    border: none;
    border-bottom: 1px solid rgba(100, 116, 139, 0.1);
    border-radius: 0;
}
.denomination-switch__container-comment {
    margin: 20px 20px 30px;
}
.denomination-switch__container-comment__title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.denomination-switch__container-comment__title svg {
    margin-right: 10px;
}
.denomination-switch__footer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.denomination-switch__footer__button {
    width: 70%;
    margin-bottom: 30px;
}
.denomination-switch__footer__link {
    margin-bottom: 30px;
}
