.shipment_detail__header__container {
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: flex-end;
}
.shipment_detail__header__container__title {
    font-weight: 700;
    font-size: 32px;
}
.shipment_detail__header__container__values--label,
.shipment_detail__header__container__values--value {
    font-size: 16px;
}
.shipment_detail__header__container__values--label {
    font-weight: 700;
}
.shipment_detail__header__container__values {
    margin-top: 10px;
}
.shipment_detail__header__container--right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.shipment_detail__header {
    height: 160px;
}
.shipments_detail {
    display: flex;
    justify-content: space-between;
    padding: 20px 30px;
}
.shipments_detail--left {
    width: 55%;
}
.shipments_detail__card {
    width: 90%;
    padding: 20px 25px;
    display: flex;
}
.shipments_detail__card--title {
    font-weight: 700;
    font-size: 20px;
}
.shipments_detail__card--left,
.shipments_detail__card--right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 50%;
}
.shipments_detail__card--right {
    align-items: flex-end;
}
.shipments_detail__card--subtitle {
    margin-top: 20px;
    font-weight: 700;
    font-size: 16px;
}
.shipments_detail__card--value {
    font-size: 14px;
    margin-top: 5px;
}
.shipments_detail__collapse {
    width: 96%;
    border: none;
    box-shadow: none;
    background-color: transparent;
}
.shipments_detail__collapse__container__column__title {
    font-size: 12px;
}
.shipments_detail__collapse__container {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    align-items: flex-end;
}
.shipments_detail__collapse__container__column {
    width: 45%;
    margin-right: 20px;
}
.shipments_detail__collapse__container__column__input {
    height: 50px;
}
.shipments_detail__collapse__container--add {
    display: none;
}
.shipments_detail__collapse__container__trashcan {
    margin-bottom: 15px;
}
.shipments_detail__collapse__container__add {
    margin-top: 20px;
}
.shipments_detail--right {
    padding: 30px 35px;
    height: fit-content;
    width: 45%;
}
.shipments_detail__table__data {
    display: flex;
    margin-bottom: 5px;
}
.shipments_detail__table__data__column,
.shipments_detail__table__data__column--titles {
    width: 25%;
}
.shipments_detail__table__data__column {
    display: flex;
    justify-content: flex-end;
}
.shipments_detail__table__data__column__title,
.shipments_detail__table__data__column__title--green {
    font-weight: 700;
    font-size: 14px;
}
.shipments_detail__table__data__column__title--value {
    font-size: 14px;
    text-align: end;
}
.shipments_detail__table__data__column__title--green {
    color: #247346;
}
.shipments_detail__table {
    margin-top: 20px;
}
.shipments_detail__card__table {
    margin-top: 0px;
}
.shipments_detail__card__table__container {
    margin-left: 30px;
    width: 96%;
    margin-bottom: 30px;
}
.shipments_detail__collapse__container__column__input .select__label--placeholder {
    width: 70%;
}
