.modal__container-main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(67, 67, 67, 0.75);
    z-index: 1000;
}

.modal__container {
    background: #fff;
    height: auto;
    border-radius: 9px;
    box-sizing: border-box;
    overflow: hidden;
    padding: 20px;
}
.modal__close {
    align-self: flex-end;
    cursor: pointer;
}
