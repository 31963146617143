.out-processing {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 30px 0;
    background-color: #fff;
}
.out-processing__form {
    padding-top: 30px;
}
.out-processing__form__title {
    line-height: 24px;
    margin-bottom: 50px;
}
.out-processing__form__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}
.out-processing__form__select {
    height: 50px;
}

.out_processing_form_select .select__icon {
    width: 20px;
    min-width: 20px;
    min-height: 20px;
    padding-right: 0;
    margin-right: 12px;
}
.out_processing_form_select .select__icon--chevron {
    min-width: 15px;
}
.out-processing__form__input {
    width: 100%;
}
.out-processing__form__touch {
    cursor: pointer;
    margin-top: 40px;
}
.out-processing__form__touch svg {
    width: 24px;
    height: 24px;
}
.out-processing__form__field_1 {
    width: 265px;
    margin-right: 40px;
}
.out-processing__form__field_2 {
    width: 265px;
    margin-right: 20px;
}
.out-processing__form__link {
    padding: 4px 0;
    margin-top: 20px;
}
.out-processing__card {
    width: 376px;
    box-shadow: 0px 8px 64px rgba(15, 34, 67, 0.05), 0px 0px 1px rgba(15, 34, 67, 0.08);
    border-radius: 5px;
    padding: 30px 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.out-processing__table__box .table__title {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
}
.out-processing__table .table__td {
    padding: 3px 0;
}
.out-processing__table .table__th {
    padding: 0 0 10px;
}
.out-processing__table tr > th:nth-child(1) {
    width: 80px;
    max-width: 80px;
}
.out-processing__table tr > th:nth-child(2) {
    width: 110px;
    max-width: 110px;
    color: var(--gray);
}
.out-processing__table__col_1 {
    font-size: 14px;
    width: 80px;
    max-width: 80px;
}
.out-processing__table__col_2 {
    width: 110px;
    max-width: 110px;
}
.out-processing__button {
    width: 310px;
    margin: 20px 0 15px;
}
