.dropdown {
    width: fit-content;
    height: auto;
    border: 1px solid #eff2f5;
    border-radius: 10px;
    box-sizing: border-box;
    background-color: #fff;
    position: absolute;
}
.dropdown__item {
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    box-sizing: border-box;
    font-size: 12px;
    font-weight: 600;
    flex-direction: column;
    padding: 10px 14px;
}
.dropdown__item:hover {
    background-color: #f0f0f0;
}
.dropdown__item:last-child {
    border-bottom: none;
}
.dropdown__item:nth-child(1) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.dropdown__item:nth-child(1):hover {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.dropdown__item:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dropdown__item:last-child:hover {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.dropdown--animation {
    -webkit-animation: animation 0.5s none ease;
    animation: animation 0.5s none ease;
}

@keyframes animation {
    from {
        transform: scale(0.8);
        opacity: 0.5;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
.dropdown__container {
    display: flex;
    flex-direction: column;
}
.drop__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #545454;
}
.drop__secondary {
    font-weight: 600;
    font-size: 9px;
    line-height: 20px;
    color: #64748b;
    opacity: 0.5;
}
.dropdown__item--logout {
    display: flex;
    border-top: 1px solid rgba(100, 116, 139, 0.25);
    padding: 10px 5px;
    margin: 0px 15px;
    justify-content: space-between;
}
