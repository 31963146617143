@keyframes animation {
    from {
        opacity: 0.5;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.select {
    width: 100%;
    background: #ffffff;
    border: 1px solid #eaeef4;
    border-radius: 8px;
    align-items: center;
    justify-content: space-between;
    padding: 7px 16px 7px 20px;
    position: relative;
    display: flex;
    cursor: pointer;
}
.select--country {
    position: relative;
    display: flex;
    cursor: pointer;
}
.select--header {
    width: 15%;
    align-items: center;
    justify-content: space-between;
    padding: 7px 16px 7px 20px;
    position: relative;
    display: flex;
    cursor: pointer;
    border-bottom: 3px solid #247346;
}
.select--header .select__label {
    font-weight: 700;
    font-size: 36px;
    color: #247346;
}
.select__label,
.select__label--placeholder {
    text-align: left;
    width: 100%;
}
.select__label--placeholder {
    color: #6d7580;
    font-weight: 400;
    font-size: 16px;
}
.select__icon {
    padding-right: 25px;
    width: 50px;
}
.select__icon--chevron {
    transition: ease-in-out 0.15s;
}
.select__dropdown {
    width: 100%;
    max-height: 160px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    z-index: 99;
    border: 1px solid #eaeef4;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 0px 0px 6px 6px;
}
.select__dropdown--animation {
    -webkit-animation: animation 0.5s none ease;
    animation: animation 0.5s none ease;
}
.select__dropdown__item {
    height: auto;
    align-items: center;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 12px;
    text-align: left;
    color: #434343;
}
.select__dropdown__item:active {
    background-color: #f0f0f0;
}
.select__dropdown__item:hover {
    background-color: #f0f0f0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
.input:-internal-autofill-selected {
    background-color: white;
}
