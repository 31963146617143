.vault-card__container {
    width: 100%;
    height: 95px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.vault-card__icon-container {
    background: rgba(72, 176, 80, 0.1);
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
.vault-card__icon {
    width: 30px;
    padding-left: 0;
}
.vault-card__container-text {
    width: 55%;
    flex-direction: column;
    text-align: start;
    margin-left: 10%;
}
.vault-card__title {
    line-height: 27px;
    font-size: 14px;
}
.vault-card__text-operation {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    color: var(--gray);
    opacity: 0.5;
}
