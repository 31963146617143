:root {
    --green: #247346;
    --ligth-green: #308b57;
    --gray: #434343;
    --light-gray: #bfbab1;
    --light: #f0f0f0;
    --red: #f24e4e;
    --background: #f7f7f9;
}

* {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-family: "Space Grotesk";
    font-style: normal;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    line-height: 27px;
    background-color: #fff;
    color: var(--gray);
}

h1 {
    font-size: 36px;
    line-height: 60px;
    font-weight: 700;
    margin: 0;
}

h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 0;
}

h3 {
    font-size: 24px;
    line-height: 40px;
    margin: 0;
    font-weight: 700;
}

h4 {
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
    margin: 0;
}

h5 {
    font-size: 18px;
    line-height: 30px;
    margin: 0;
}

.small {
    font-size: 14px;
    line-height: 27px;
}

.x-small {
    font-size: 12px;
    line-height: 20px;
    margin: 0;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

input:focus,
textarea:focus {
    border: none;
    outline: none;
}
p {
    margin: 0;
}

.flex-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}
a:link,
a:visited,
a:active {
    text-decoration: none;
    color: var(--gray);
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover,
input:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px white inset;
}
