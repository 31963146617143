.shipments_in {
    display: flex;
    flex-direction: column;
    padding: 30px 10%;
    background: white;
}
.shipments_in__title {
    font-weight: 700;
    font-size: 20px;
}
.shipments_in__card {
    margin-top: 35px;
    padding: 40px 35px;
}
.shipments_in__card__title {
    font-weight: 700;
    font-size: 16px;
}
.shipments_in__card__columns__item {
    display: flex;
    flex-direction: column;
}
.shipments_in__card__columns {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
