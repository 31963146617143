.input {
    height: 50px;
    width: 300px;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #eaeef4;
    border-radius: 8px;
    background-color: #fff;
    font-size: 16px;
}
.input input {
    height: 100%;
    width: 100%;
    border: none;
    background-color: transparent;
    font-weight: 400;
    font-size: 1em;
}
.input input::placeholder {
    color: #7e92a2;
}
.input__icon {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
    margin-right: 12px;
}
.input--state--error {
    border-color: #eb5757;
}
.input--state--error .input__icon path {
    fill: #eb5757;
}

.field {
    width: auto;
    height: auto;
}
.field__label {
    font-size: 12px;
    font-weight: 400;
    line-height: 30px;
    position: relative;
    display: flex;
}
.field__label--error {
    color: #eb5757;
}
