.table__container {
    width: 100%;
    background-color: #fff;
}
.table__title {
    font-size: 16px;
    line-height: 16px;
    margin: 0 0 20px;
}
.table__box {
    width: 100%;
    max-width: 100%;
    overflow: auto;
}
.table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: auto;
    border: 1px solid transparent;
}
.table__th {
    font-size: 14px;
    text-align: right;
    padding: 0px 20px;
    color: var(--green);
}
.table__td {
    font-size: 12px;
    text-align: right;
    padding: 15px 20px;
}
.table__td:first-child {
    text-align: left;
}
.table__td__bold {
    font-weight: 700;
}
.table__border .table__td {
    border-bottom: 0.6px solid #bfbab180;
}
.table__td__input {
    width: 130px;
    font-size: 12px;
}
.table__border tbody > tr:last-child > .table__td {
    border-bottom: none;
}
.table__td__total {
    color: var(--green);
}
