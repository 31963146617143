.close-vault__screen {
    padding: 30px 30px 40px;
    background-color: #fff;
}
.close-vault__head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}
.close-vault__button {
    width: 290px;
}
.close-vault__main {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.close-vault__table {
    padding: 30px 10px 10px;
    box-shadow: 0px 8px 64px rgba(15, 34, 67, 0.05), 0px 0px 1px rgba(15, 34, 67, 0.08);
    border-radius: 10px;
}
.close-vault__table > .table__title {
    font-size: 20px;
    line-height: 20px;
    margin: 0 0 30px 20px;
}
.close-vault__table__simple td {
    text-align: right !important;
}
.close-vault__table__simple th:first-child {
    max-width: 60px;
    padding: 0;
}
.close-vault__table__simple td:first-child {
    max-width: 60px;
    padding: 15px 0;
}
.close-vault__collapse {
    border: none;
    padding: 30px 10px 20px;
    box-shadow: 0px 8px 64px rgba(15, 34, 67, 0.05), 0px 0px 1px rgba(15, 34, 67, 0.08);
}
.close-vault__collapse > .collapse__head {
    padding: 0 20px;
    margin-bottom: 20px;
}
.close-vault__table__complete th:first-child {
    max-width: 95px;
}
.close-vault__table__complete td:first-child {
    max-width: 95px;
    line-clamp: 2;
    line-height: 17px;
}
.close-vault__button__secondary {
    width: 590px;
    margin: 40px 0 20px;
}
.close-vault__note {
    font-weight: 500;
    font-size: 1rem;
    opacity: 0.5;
}
.close-vault__mb {
    margin-bottom: 30px;
}
