.payment__screen {
    background-color: #fff;
    padding-bottom: 40px;
}
.payment__head {
    width: 100%;
    height: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f0f0f0;
}
.payment__main {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payment__table__box {
    padding: 0 10px;
}
.payment__table__box .table__title {
    margin: 0 0 30px 20px;
}
.payment__table__editable .table__td__input {
    width: 100%;
}
.payment__table__card {
    width: calc(100% - 60px);
    box-shadow: 0px 8px 64px rgba(15, 34, 67, 0.05), 0px 0px 1px rgba(15, 34, 67, 0.08);
    border-radius: 5px;
    margin-top: 30px;
    padding: 25px 10px 10px;
}
.payment__table__card .table__title {
    margin: 0 0 30px 10px;
}
.payment__button {
    width: 840px;
    margin: 25px 0 15px;
}
