.textarea,
.textarea:focus {
    resize: none;
    background: #ffffff;
    border: 1px solid #eaeef4;
    border-radius: 8px;
    width: 100%;
    height: fit-content;
    padding: 10px 20px 0px;
}
