.shipments {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0px;
}
.shipments__card {
    width: 60%;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}
.shipments__card__info--title {
    font-weight: 700;
    font-size: 20px;
    margin-top: 15px;
}
.shipments__card__info--subtitle {
    font-size: 16px;
}
.shipments__card__info {
    display: flex;
    flex-direction: column;
}
.shipments__card__icon {
    cursor: pointer;
}
