.notification__container {
    height: auto;
    width: 100%;
}
.notification__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.notification__contain {
    height: 100%;
}
.notification__item {
    margin: 10px 0;
    padding: 10px 0;
    border-bottom: 1px solid rgba(67, 67, 67, 0.15);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.notification__item__title {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
}
.notification__item__text,
.notification__item__time {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    opacity: 0.5;
}
.notification__item__text-time {
    font-size: 10px;
}
.notification__item__container--read {
    width: 15px;
    display: flex;
    align-items: flex-start;
    margin-top: 6px;
}
.notification__item--read {
    width: 8px;
    height: 8px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: var(--ligth-green);
}
