.tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
}
.tabs__item {
    padding: 12px 30px;
    border-bottom: 4px solid transparent;
    cursor: pointer;
    transition: all 0.1s ease-in;
}
.tabs__item__label {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
}
.tabs__item--active {
    border-bottom-color: var(--green);
}
.tabs__item--active .tabs__item__label {
    font-weight: 700;
}
