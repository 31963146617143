.container-top {
    width: 100%;
    min-height: 125px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    overflow-x: hidden;
    background-color: var(--light);
    align-items: center;
    justify-content: center;
}
.container-top__back {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    margin: 15px 0 0 30px;
    cursor: pointer;
}
.container-top__icon-back {
    width: 10px;
    height: 10px;
    margin-right: 5px;
}
.container-top__icon-back path {
    fill: var(--green);
}
.container-top__text-back {
    color: var(--green);
    font-weight: 700;
    font-size: 14px;
}
