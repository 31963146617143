.transfer-card__container-main {
    width: 60%;
    min-width: 776px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
}
.transfer-card__container-left {
    display: flex;
    flex-direction: column;
}
.transfer-card__container-right {
    justify-content: flex-end;
}
.transfer-card__icon {
    background: rgba(72, 176, 80, 0.15);
    width: 46px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-bottom: 10px;
}
.transfer-card__text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray);
}
.transfer-card__title {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 7px;
}
