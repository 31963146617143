.layout__container {
    height: 100vh;
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr;
    overflow: hidden;
}
.layout__header {
    background-color: var(--gray);
}
.layout__main {
    display: grid;
    grid-template-columns: 110px 1fr;
    overflow: hidden;
}
.layout__page {
    background-color: var(--background);
    overflow-y: auto;
}
