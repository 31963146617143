.drawer__container {
    height: 100%;
    background-color: var(--green);
    position: relative;
    transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    padding: 30px 0 30px 30px;
    z-index: 999;
}
.drawer--max {
    width: 255px;
    padding-right: 15px;
}
.drawer--min {
    width: 110px;
    padding-right: 30px;
}
.drawer--min .drawer__item {
    width: 46px;
    border-radius: 35px;
    padding-left: 0;
    justify-content: center;
}
.drawer--min .drawer__item__label {
    display: none;
}
.drawer__btn {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #48b050;
    position: absolute;
    top: 5px;
    right: -12px;
    cursor: pointer;
}
.drawer__content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.drawer__item {
    height: 46px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
    border-radius: 10px;
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    margin-bottom: 10px;
}
.drawer__item--active {
    background-color: #ffffff1a;
}
.drawer__item__icon {
    width: 24px;
    height: 24px;
}
.drawer__btn__icon {
    height: 12px;
    width: 7px;
}
.drawer__btn__icon--rotate {
    transform: rotate(180deg);
}
.drawer__item__label {
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: #fff;
    margin-left: 18px;
    white-space: nowrap;
}
