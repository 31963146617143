.transfer-prepak__screen {
    width: 100%;
    padding-bottom: 40px;
    background-color: #fff;
}
.transfer-prepak__layout {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.transfer-prepak__container {
    width: 100%;
    padding: 0 30px;
}
.transfer-prepak__form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.transfer-prepak__form__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}
.transfer-prepak__collapse {
    border: none;
    box-shadow: none;
    text-transform: capitalize;
}
.transfer-prepak__form__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    margin-top: 15px;
}
.transfer-prepak__form__select {
    height: 50px;
}
.transfer-prepak__form__input--hidden {
    display: none;
}
.transfer-prepak__form__select .select__icon {
    width: 20px;
    min-width: 20px;
    min-height: 20px;
    padding-right: 0;
    margin-right: 12px;
}
.transfer-prepak__form__select .select__icon--chevron {
    min-width: 15px;
}
.transfer-prepak__form__input {
    width: 100%;
}
.transfer-prepak__form__touch {
    cursor: pointer;
}
.transfer-prepak__form__touch svg {
    width: 24px;
    height: 24px;
}
.transfer-prepak__form__field1 {
    width: 265px;
    margin-right: 40px;
}
.transfer-prepak__form__field2 {
    width: 265px;
    margin-right: 20px;
}
.transfer-prepak__card {
    width: 500px;
    height: 620px;
    box-shadow: 0px 8px 64px rgba(15, 34, 67, 0.05), 0px 0px 1px rgba(15, 34, 67, 0.08);
    border-radius: 5px;
    padding: 30px 30px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.transfer-prepak__table tr > th {
    color: var(--gray);
}
.transfer-prepak__table tr > th:nth-child(5) {
    color: var(--green);
}
.transfer-prepak__table-inventory {
    width: calc(100% - 60px);
    box-shadow: 0px 8px 64px rgba(15, 34, 67, 0.05), 0px 0px 1px rgba(15, 34, 67, 0.08);
    border-radius: 5px;
    margin-top: 30px;
    padding: 25px 10px 10px;
}
.transfer-prepak__table-inventory .table__title {
    margin: 0 0 30px 10px;
}
.transfer-prepak__button {
    width: 400px;
    height: 40px;
    margin: 20px 0 15px;
}
