.link {
    width: fit-content;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #247346;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
