.collapse {
    width: 100%;
    height: auto;
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px;
    box-shadow: 0px 8px 64px rgba(15, 34, 67, 0.05), 0px 0px 1px rgba(15, 34, 67, 0.08);
    border-radius: 10px;
    border: #bfbab1 0.88px solid;
}
.collapse__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.collapse__title {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}
.collapse__icon path {
    fill: #247346;
}
