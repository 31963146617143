.country__header__title__item {
    font-weight: 700;
    font-size: 36px;
    margin-right: 15px;
}
.country__header__title {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: baseline;
}
.country__header__subtitle {
    font-weight: 400;
    font-size: 16px;
    margin-top: 15px;
}
.country {
    overflow-x: visible;
    background: white;
}
.country__header {
    height: 250px;
    overflow-x: visible;
}
.country__header__selects {
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
}
.country__header__selects .select {
    width: 32%;
}
.country__header__selects .select .select__label--placeholder {
    width: 80%;
}
.country__header__selects .select .select__label {
    width: 80%;
}
.country__body__row {
    display: flex;
    justify-content: space-between;
    width: 80%;
    align-items: center;
    margin-bottom: 30px;
}
.country__titles {
    display: flex;
    width: 60%;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 20px;
    justify-content: space-between;
    margin-left: 10%;
    margin-top: 30px;
}
.country__body {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.country__add {
    display: flex;
    font-weight: 700;
    font-size: 16px;
    width: 74%;
    margin-left: 10%;
    justify-content: space-between;
}
.country__add__totals {
    width: 49%;
    display: flex;
    justify-content: space-between;
}
.country__link {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0px;
}
