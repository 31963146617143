.button {
    width: 300px;
    height: 50px;
    border: none;
    border-radius: 70px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    cursor: pointer;
}
.button:disabled {
    opacity: 0.5;
}
.button__icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.button__icon svg {
    width: 18px;
    height: 18px;
}
.button__icon span {
    font-size: 1em;
    font-weight: 700;
    margin-left: 12px;
}

.button--primary {
    background-color: var(--green);
}
.button--primary:hover {
    background-color: #2d9158;
}
.button--primary:active {
    box-shadow: 0px 8px 64px rgba(15, 34, 67, 0.05), 0px 0px 1px rgba(15, 34, 67, 0.08),
        inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.button--secondary {
    color: var(--green);
    background-color: #fff;
    border: 2px solid var(--green);
}
.button--secondary:hover {
    background-color: #f3fff8;
}

.button--link {
    width: auto;
    height: auto;
    color: var(--green);
    background-color: transparent;
    border-radius: 0;
    line-height: 20px;
    padding: 4px 15px;
}
.button--link:active {
    opacity: 0.5;
}
